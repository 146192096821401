import React from 'react'
class Certificate extends React.Component {
    componentDidMount() {
        console.log(this.props)
    }
    render() {
        return (
            <div className="Certificate">
                welcome
            </div>
        )
    }
}

export default Certificate
