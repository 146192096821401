import './App.css';
import Home from './pages/Home/Home'
import TypeIn from './pages/TypeIn/TypeIn'
import Certificate from './pages/Certificate/Certificate'
import Jadetype from './pages/Jadetype/Jadetype';
import NotFount from './pages/NotFount/NotFount';
// import Value from './pages/Value/Value'
import {
  // useRoutes,
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
// function Ele() {
//   return useRoutes([
//     {
//       path: '/home',
//       element: <Home />,
//       children: [
//         {
//           path: 'certificate',
//           element: <Certificate />
//         },
//         {
//           path: 'typein',
//           element: <TypeIn />
//         }
//       ]
//     },
//     {
//       path: '/',
//       re
//     }
//   ])
// }
function App() {
  // return(
  //   <BrowserRouter>
  //     <Ele />
  //   </BrowserRouter>
  // )
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/home' element={<Home />}>
          <Route index element={<Certificate />} />
          <Route path="typein" element={<TypeIn />} />
          <Route path="jadetype" element={<Jadetype />} />
          {/* <Route path="*" element={<Navigate to="certificate" />} /> */}
        </Route>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" element={<NotFount />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
