import React from 'react'
class TypeIn extends React.Component {
    componentDidMount() {
        console.log(this.props)
    }
    render() {
        return (
            <div>
                {/* TypeIn */}
            </div>
        )
    }
}

export default TypeIn
