import React, { Component } from 'react'
import { Form, Input, Button, Modal, Typography } from 'antd'

export default class Jadetype extends Component {
    state = { visible: false, typeName: "" }

    getType = (num) => {
        if (num >= 2.9 && num < 3.0) {
            return "和田玉"
        } else if (num >= 3.3 && num < 3.4) {
            return "翡翠"
        } else if (num >= 2.6 && num < 2.7) {
            return "玛瑙 或 石英岩玉 或 绿松石"
        } else if (num >= 2.4 && num < 2.6) {
            return "岫岩玉 或 绿松石"
        } else if (num >= 2.7 && num < 2.9) {
            return "独山玉 或 绿松石"
        } else {
            return "未知"
        }
    }
    onFinish = (values) => {
        let { first, second } = values
        let num = first / second
        let typeName = this.getType(num)
        this.setState({
            typeName,
            visible: true   
        })
    };

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
    render() {
        return (
            <div>
                <Form
                    name="basic"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    // initialValues={{ first: 45.3, second: 15.2 }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="第一重量（g）"
                        name="first"
                        rules={[{ required: true, message: '请输入第一重量' }]}
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        label="第二重量（g）"
                        name="second"
                        rules={[{ required: true, message: '请输入第二重量' }]}
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        label="提示"
                    >
                        <div style={{height: '5px'}}></div>
                        <Typography.Paragraph>
                            第一重量为玉石放在水中的重量，第二重量为玉石不接触盛水容器且完全沉在水中的重量
                        </Typography.Paragraph>
                    </Form.Item>
                    {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Submit提交
                        </Button>
                    </Form.Item>
                </Form>
                <Modal
                    title="提示"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                          确定
                        </Button>,
                      ]}
                >
                    <p>玉的类型为：{this.state.typeName}</p>
                </Modal>
            </div>
        )
    }
}
