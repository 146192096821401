import React, { Component } from 'react'

export default class NotFount extends Component {
    render() {
        return (
            <div style={{backgroundColor: '#243a54', height: '100%'}}>
                <img src="/404.jpeg" alt="404" width="100%"/>
            </div>
        )
    }
}
