import React from 'react'
import './Home.scss'
import { Link, Outlet } from 'react-router-dom'
import { Layout, Menu } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { createBrowserHistory } from 'history';
 
const history = createBrowserHistory();

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
export default class Home extends React.Component {
    state = {
        collapsed: false,
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    async componentDidMount() {
        let paramsString = history.location.search.substring(1)
        const searchParams = new URLSearchParams(paramsString)

        const id = searchParams.get('id')
        console.log(id)
        setTimeout(() => {
            
        }, 3000);
        // let data = {
        //     a: 1,
        //     b: 2
        // }
        // try {
        //     let response = await fetch("http://localhost:5500", {
        //         method: "GET",
        //         catch: "no-cache",
        //         // credentials: "same-origin",
        //         // headers: {
        //         //     "content-Type": "application/json"
        //         // },
        //         redirect: "follow",
        //         referrerPolicy: "no-referrer",
        //         data
        //         // body: JSON.stringify(data)
        //     })
        //     let result = await response.json()
        //     console.log(result)
        // } catch(e) {
        //     console.log(e)
        // }
        // var xhr = new XMLHttpRequest()
        // xhr.onreadystatechange = function() {
        //     if (xhr.readyState === 4) {
        //         console.log(xhr.response)
        //     }
        // }
        // xhr.open("GET", "http://localhost:5500", true)
        // xhr.send(null)
    }
    render() {
        return (
            <div className="Home">
                <Layout>
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                        <div className="logo">{this.state.collapsed ? "" : "玉石评审系统"}</div>
                        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                            <SubMenu key="sub1" icon={<UserOutlined />} title="玉石鉴定">
                                <Menu.Item key="1"><Link to="/home">鉴定列表</Link></Menu.Item>
                                <Menu.Item key="2"><Link to="jadetype">比重鉴定</Link></Menu.Item>
                                <Menu.Item key="3"><Link to="typein?id=1">玉器录入</Link></Menu.Item>
                                {/* <Menu.Item key="4">Alex</Menu.Item> */}
                            </SubMenu>
                            <SubMenu key="sub2" icon={<TeamOutlined />} title="玉器估值">
                                <Menu.Item key="5">估值列表</Menu.Item>
                                <Menu.Item key="6">估值录入</Menu.Item>
                            </SubMenu>
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }}>
                            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: this.toggle,
                            })}
                        </Header>
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 280,
                            }}
                        >   
                            {/* <Routes>
                                <Route path="Certificate" element={<Certificate />}></Route>
                                <Route path="TypeIn" element={<TypeIn />} />
                            </Routes> */}
                            <Outlet id="1"/>
                        </Content>
                    </Layout>
                </Layout>
            </div>
        )
    }
}